const customFetch = (settings: RequestInfo, options: RequestInit = {}) => {
  options.credentials = "same-origin"

  const request = new Request(settings, options)
  request.headers.set("X-Requested-With", "XMLHttpRequest")

  if (request.method !== "GET") {
    const csrfmeta = document.querySelector<HTMLMetaElement>('meta[name="csrf-token"]')
    if (csrfmeta) {
      request.headers.set("X-CSRF-Token", csrfmeta.content)
    }
  }

  return fetch(request)
}

export default customFetch
